$text-dark-grey: #3a3a3a;
$text-light-grey: #6b6b6b;
$text-white: #ffffff;

.app {
  width: 90rem;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  @media screen and (max-width: 768px) {
      flex-direction: column;
  }
}