// Amirhossein Rahmati - April 2021
// ------------------------------------------
$text-dark-grey: #3a3a3a;
$text-light-grey: #6b6b6b;
$text-white: #ffffff;
// ------------------------------------------
* {
    box-sizing: border-boxr;
    margin: 0;
    padding: 0;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing:antialiased;

    &::selection {
        color: $text-white;
        background: $text-light-grey;
    }
}

html {
    font-family: 'Roboto', serif;
}

@keyframes hide {
    from {width: calc(50% - 1em)}
    to {width: 0%;}
}