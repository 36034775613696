$text-dark-grey: #3a3a3a;
$text-light-grey: #6b6b6b;
$text-white: #ffffff;

.masked-character {
    max-height: 100vh;
    height: 70vh;
    width: calc(50% - 1em);
    margin-right: 1em;
    position: relative;
    display: flex;
    pointer-events: none;
  
    > svg {
        width: 100%;
        height: auto;
        font: bold 4em 'Noto Serif', serif;
      
        .image-mask {
            fill: url(#masked-image);
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 30vh;
        margin-right: 0;
        margin-bottom: 1em;
    }
}