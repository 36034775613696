$text-dark-grey: #3a3a3a;
$text-light-grey: #6b6b6b;
$text-white: #ffffff;

.masked-not-found-character {
    max-height: 100vh;
    height: 50vh;
    width: 90%;
    margin: 4em auto 0 auto;
    position: relative;
    display: flex;
    pointer-events: none;

    > svg {
        width: 100%;
        height: auto;
        font: bold 4em 'Noto Serif', serif;
      
        .image-mask {
            fill: url(#masked-image);
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 30vh;
        margin-right: 0;
    }
}

.not-found-error-message {
    font-family: 'Noto Serif', serif;
    color: $text-dark-grey;
    margin-bottom: .75em;
    width: 90%;
    text-align: center;
    margin: 0 auto;
    font-size: 2em;

    @media screen and (max-width: 768px) {
        font-size: 1em;
        line-height: 1.8;
        width: 70%;
    }
}