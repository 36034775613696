$text-dark-grey: #3a3a3a;
$text-light-grey: #6b6b6b;
$text-white: #ffffff;

.text-wrapper {
    width: 50%;

    .title {
        color: $text-dark-grey;
        margin-bottom: .75em;
        font-family: 'Noto Serif', serif;
    }

    .more-detail {
        color: $text-light-grey;
        line-height: 1.65;
        font-size: 1.25em;
        margin-bottom: 1.5em;

        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1em;

            span {
                font-size: .85em;
            }

            h2 {
                font-size: .75em;
                margin-right: .25em;
                font-weight: 600;
            }

            a {
                text-decoration: none;
                border-bottom: dotted 1px #fff;
                color: $text-light-grey;
                font-size: .7em;
                transition: .2s all;

                &:hover {
                    color: $text-dark-grey;
                    border-bottom-color: $text-dark-grey;
                }
            }
        }
    }

    > nav > ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        > li {
            margin: 0 2em .75em 0;

            &:last-of-type {
                margin: 0;
            }

            > a {
                text-decoration: none;
                border-bottom: dotted 1px #fff;
                color: $text-light-grey;
                font-size: 1.15em;
                transition: .2s all;

                &:hover {
                    color: $text-dark-grey;
                    border-bottom-color: $text-dark-grey;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;

        .title {
            font-size: 1.5em;
        }

        .more-detail {
            font-size: 1em;

            > div {
                align-items: center;
            }
        }

        > nav > ul {
            align-items: center;
            justify-content: center;

            > li {
                margin: .75em;

                > a {
                    font-size: 1em;
                }

                &:last-of-type {
                    margin: .75em;
                }
            }
        }
    }
}
